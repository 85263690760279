progress {
  border-radius: 7px;
  width: 100%;
  height: 17px;
}
progress::-webkit-progress-bar {
  background-color: #e8e8e8;
  border-radius: 65px;
}
.dark progress::-webkit-progress-bar {
  background-color: #676767;
}
progress::-webkit-progress-value {
  background-color: #2b2b2b;
  border-radius: 65px;
}
.dark progress::-webkit-progress-value {
  background-color: #eaeaea;
}
